:root {
  --avatar-size: 44px;
}
.avatar {
  min-width: var(--avatar-size);
  min-height: var(--avatar-size);
  background-image: url("../images/avatar.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: calc(var(--avatar-size) / 2);
}
