.container {
  margin-top: 80px;
  padding: 40px;
  align-items: center;
  color: var(--footer-text-color);
  background-color: var(--footer-background-color);
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .container {
    margin-top: 35px;
    padding: 30px 20px 30px 20px;
  }
}
