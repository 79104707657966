.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 15px auto 45px auto;
  overflow: visible;
  color: var(--card-text-color);
  background-color: var(--card-background-color);
}

@media screen and (max-width: 1024px) {
  .container {
    margin: 15px auto 15px auto;
  }
}
