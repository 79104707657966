:root {
  --gallery-fullscreen-caption-height: 50px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.figure {
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  margin-block-start: 0;
  margin-block-end: 0;
  max-height: 98%;
  width: calc(100% - 20px);
}

.picture {
  /* min-width: 70vw; */
  width: 100%;
  height: calc(100% - var(--gallery-fullscreen-caption-height));
  object-fit: contain;
  object-position: center;
}

.caption {
  padding: 10px 0;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  background-color: inherit;
  color: var(--background-text-color);
  font-size: 14px;
  line-height: normal;
  /* height: var(--gallery-fullscreen-caption-height); */
  /* max-width: 290px; */
}
