.container {
  border-top: var(--card-section-divider);
  margin-top: 15px;
  position: relative;
}

.button {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.commentsHidden {
  padding: 0px;
  min-height: 0px;
  transition: min-height 0.3s ease-in-out;
}

.comments {
  padding: 35px 20px 0px 20px;
  min-height: 300px;
  transition: min-height 0.3s ease-in-out;
}
