.container {
  min-width: unset;
  margin-left: 5px;
  padding: 0px;
}

.container:global(.mdl-textfield label) {
  position: absolute;
  top: unset;
  bottom: unset;
}

.container:global(.mdl-textfield input) {
  margin-left: 5px;
}
