.buttonLink {
  transform: scale(1.4);
}

.titleLink {
  color: var(--header-text-color);
  text-decoration: none;
}

.title {
  margin-left: 30px;
  font-size: 50px;
  font-family: "Mysticaln";
  letter-spacing: 2px;
  color: var(--header-text-color);
}

.logo {
  background-color: var(--header-text-color);
  -webkit-mask-image: url("../../images/llama-vector.svg");
  mask-image: url("../../images/llama-vector.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;

  /* background-image: url("../../images/llama-vector.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; */

  width: 100%;
  height: 100%;
  transform: scale(0.9);
}

@media screen and (max-width: 1024px) {
  .buttonLink {
    transform: none;
  }

  .title {
    margin-left: 15px;
    font-size: 28px;
  }
}

@media screen and (max-width: 500px) {
  .buttonLink {
    transform: scale(0.7);
  }

  .title {
    margin-left: 0px;
    font-size: 24px;
  }
}
