.container {
  background-color: var(--background-color);
  padding: 0px 8px 0px 8px;
  margin: 45px auto 5px auto;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 0px 2px 0px 2px;
  }
}

.dots {
  color: red;
}

.slider {
  max-width: calc(100% - 50px);
  width: 100vw;
}

/* bit of a hack to have a more specific selector.. */
.dialog.dialog {
  width: 100%;
  max-width: unset;
  height: 100%;
  max-height: unset;
  padding: 0;
  background-color: var(--background-color);
  color: var(--background-text-color);
}

.dialogContent {
  height: 100%;
  padding: 0 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dialogCloseButton {
  position: absolute;
  top: 20px;
  right: 20px;
}
