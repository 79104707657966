.header {
  composes: mdl-layout__header from global;
  background-color: var(--header-background-color);
  color: var(--foreground-text-color);
  padding-top: 30px;
  padding-bottom: 30px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.4s;
}

.scrollDown .header {
  transform: translate3d(0, -100%, 0);
}

.scrollUp .header {
  transform: none;
}

.headerRow {
  composes: mdl-layout__header-row from global;
  padding-left: 40px;
}

@media screen and (max-width: 1024px) {
  .header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .headerRow {
    padding-left: 20px;
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .headerRow {
    padding: 0px 5px 0px 5px;
  }
}
