.button {
  background-color: inherit;
  color: var(--button-default-background);
  position: absolute;
  top: 50%;
  transform: translate(0, -65%);
}

@media (hover: none) {
  .button:hover:global(.mdl-button:hover) {
    background-color: inherit;
  }
}

.left {
  left: -30px;
}

.right {
  right: -30px;
}
