:root {
  --content-width: 800px;

  --background-color: #28303c; /* rgb(214, 225, 230);*/
  --background-text-color: rgb(245, 245, 245); /* rgb(63, 83, 113);*/

  --foreground-color: rgb(245, 245, 245);
  --foreground-text-color: #28303c; /* rgb(63, 83, 113);*/

  --header-background-color: var(--foreground-color);
  --header-text-color: var(--foreground-text-color);

  --card-background-color: var(--foreground-color);
  --card-title-color: var(--foreground-color);
  --card-media-background-color: rgb(176, 196, 222);
  --card-text-color: var(--foreground-text-color);
  --card-padding: 16px;
  --card-font-size: 16px;
  --card-section-divider: 1px solid rgba(0, 0, 0, 0.2);
  --card-media-breakpoint: 800px; /* 1024px; */ /* TODO : breakpoint is not configurable yet */

  --footer-background-color: var(--foreground-color);
  --footer-text-color: var(--foreground-text-color);

  --button-default: var(--foreground-text-color);
  --button-default-background: rgb(236, 236, 236);
  --button-default-highlight: rgb(214, 214, 214);

  --button-primary: var(--foreground-text-color);
  --button-primary-background: rgb(253, 124, 255);
  --button-primary-highlight: rgb(232, 157, 233);

  --button-accent: var(--foreground-text-color);
  --button-accent-background: var(--foreground-color);
  --button-accent-highlight: rgb(236, 236, 236);

  --button-flat-highlight: rgba(200, 200, 200, 0.3);
}

@font-face {
  font-family: "Mysticaln";
  src: local("Mysticaln"), url(./fonts/MYSTCALN.TTF) format("truetype");
  /* url(./fonts/DISTInking-Bold.otf) format("opentype"); */
}

body {
  font-family: "Roboto", "Helvetica", sans-serif;
  background-color: var(--background-color);
}

/* THESE STYLES ARE IMPORTANT FOR THE EDITOR AS WELL */

.mdl-card__supporting-text {
  width: 100%;
  padding: var(--card-padding);
  color: var(--card-text-color);
  display: block;
  position: relative;
  box-sizing: border-box;
  min-height: 76px;
}

.mdl-card__supporting-text p:last-child {
  margin-bottom: 0;
}

.mdl-card__supporting-text p {
  font-size: var(--card-font-size);
  line-height: 22px;
  text-align: justify;
}

.mdl-card__supporting-text img {
  width: 100%;
  height: auto;
  padding: 10px 0px;
}

.mdl-card__supporting-text iframe {
  width: 100%;
}

.mdl-card__supporting-text ~ .mdl-card__supporting-text {
  border-top: var(--card-section-divider);
}

.post-actions {
  display: flex;
  background-color: transparent;
  max-width: 800px;
  width: 100%;
  margin: 0px 8px 0px 8px;
}
.post-actions button {
  margin-left: 10px;
}

/* Fixes for IE 10 */
.mdl-grid {
  display: flex !important;
}

.mce-content-body {
  margin: 0;
  width: 100%;
  overflow: auto;
}

.editor-body {
  background-color: var(--card-background-color);
}

.my-tip {
  display: flex;
  padding: 15px 80px 15px 40px;
  font-style: italic;
}
.my-tip::before {
  content: "";

  background-color: var(--header-text-color);
  -webkit-mask-image: url("./images/llama-vector.svg");
  mask-image: url("./images/llama-vector.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top;
  mask-position: top;

  /* background-image: url("./images/llama-vector.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top; */

  width: 40px;
  height: 40px;
  min-width: 70px;
  min-height: 70px;
  margin-right: 10px;
}

.my-disclaimer {
  padding: 15px 40px 15px 40px;
  display: block;
}
.my-disclaimer::before {
  content: "* Disclaimer";
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
}

.my-footnote {
  border-top: var(--card-section-divider);
  padding: 15px 0px 15px 0px;
  display: block;
}
.my-footnote::after {
  content: "Gracias por leer.";
  display: block;
  text-align: end;
  margin-top: 10px;
  font-weight: normal;
  font-family: "Mysticaln";
  font-size: 28px;
  letter-spacing: 1px;
}

/* TODO : rename to post-img or something like that.. will have to change it in post content as well */
.img-text {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: normal;
  padding: 10px 0px;
}

.post-intro-text {
  font-weight: bold; /* 500 not visible with my font? */
}

@media screen and (max-width: 1024px) {
  .my-tip {
    padding: 10px 40px 10px 0px;
  }

  .my-tip::before {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    /* margin-right: 5px;   */
  }
}
