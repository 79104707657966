.wrapper {
  display: flex;
  justify-content: center;
}

.figure {
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.picture {
  width: 100%;
  /* max-width: 300px; */
}

.caption {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-color: inherit;
  color: var(--background-text-color);
  padding-top: 10px;
  text-align: center;
  /* background-color: var(--foreground-color);
  color: var(--foreground-text-color); */
  font-size: 12px;
  line-height: normal;
  /* max-width: 290px; */
}
