.container {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 20;
  background-color: rgb(15, 15, 15);
}

.text {
  margin-right: 10px;
  padding: 20px;
  color: var(--foreground-color);
}

.spacer {
  flex-grow: 1;
}

.action {
  padding: 20px;
  min-width: fit-content;
}

@media (min-width: 900px) {
  .container {
    width: 900px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
