.link {
  width: 50%;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--background-text-color);
}
.link span {
  flex-grow: 1;
  margin: 0px 16px 0px 16px;
}
.link:first-child {
  text-align: left;
  width: 100%;
}
.link:last-child {
  text-align: right;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .link {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.02em;
  }

  .link span {
    margin: 0px 8px 0px 8px;
  }
}
