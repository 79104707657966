.media {
  padding: 24px;
  display: flex;
  flex-grow: 1;
  flex-direction: column-reverse;
  align-items: flex-start;
  height: 400px;
  position: relative;
}

.media img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* TODO : set this by variable */
@media screen and (max-width: 800px) {
  .media {
    height: 260px;
  }
}
