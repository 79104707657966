.action-menu {
  position: fixed;
  display: block;
  right: 0;
  bottom: 0;
  margin-right: 40px;
  margin-bottom: 40px;
  z-index: 900;
}

.action-menu .mdl-menu__outline {
  visibility: hidden;
}

.action-menu .mdl-menu__item {
  background-color: transparent;
  padding: 0;
  text-align: end;
  cursor: default;
}

.action-menu .mdl-menu__item:hover {
  background-color: transparent;
}

.action-menu li button {
  background-color: white;
  border: solid 1px darkgrey;
  border-radius: 24px;
  line-height: 24px;
}

.action-menu li button:hover {
  background-color: rgb(230,230,230);
}

.action-menu li button span {
  vertical-align: middle;
  margin-right: 3px;
}

.action-menu li button i {
  color: dimgrey;
}
  
@media screen and (max-width: 1024px) {
  .action-menu {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
