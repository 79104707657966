.content {
  padding-top: 80px;
  position: relative;
  overflow: visible; /* MDL fix for hiding address bar on scroll? */
}

.blog {
  max-width: var(--content-width);
  display: flex;
  flex-shrink: 0;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .content {
    padding-top: 20px;
  }
}
