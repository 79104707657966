.container {
    padding: 5px;
    width: fit-content;
    display: flex;
    flex-direction: row;
}
.selector {
    border-bottom: 0;
}
.flag {
    padding-left: 18px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: 16px 16px;
}
.flagCze {
    background-image: url("../images/czech-republic-small.png");
}
.flagEng {
    background-image: url("../images/united-kingdom-small.png");
}