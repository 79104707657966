.wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.figure {
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  /* margin-block-start: 0;
  scroll-margin-block-end: 0; */
}

.picture {
  width: 100%;
  /* max-width: 300px; */
}

.captionInput {
  width: 100%;
}

.caption {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-color: inherit;
  color: var(--background-text-color);
  padding: 10px 0;
  text-align: center;
  font-size: 12px;
  line-height: normal;
  /* max-width: 290px; */
}

.captionTextArea {
  resize: none;
  padding: 0;
  text-align: center;
  font-size: 12px;
  width: 100%;
}

.buttonGroup {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  position: absolute;
}

.button {
  margin: 5px;
}
