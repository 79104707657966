.container {
  margin: 30px 15px 0px 15px;
  display: flex;
  flex-direction: row;
  font-weight: 500;
}

@media screen and (max-width: 1024px) {
  .container {
    margin: 25px 12px 0px 12px;
  }
}
