.title {
  margin: 0;
  color: var(--card-title-color);
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.68);
  z-index: 4;
}

.title a {
  text-decoration: none;
  color: inherit;
}
