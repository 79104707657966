.tagChip {
  composes: mdl-chip from global;
  margin-left: 5px;
  color: var(--button-default);
  background-color: var(--button-default-background);
}

.tagChip:focus,
.tagChip:active {
  background-color: var(--button-default-highlight);
}

.tagChip i {
  color: var(--button-default);
}
