.button {
  color: var(--button-default);
  background-color: var(--button-default-background);
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
}

.button:active,
.button:focus,
.button:focus:not(:active) {
  color: var(--button-default);
  background-color: var(--button-default-highlight);
}

.flatButton {
  color: inherit;
  background-color: inherit;
}

.flatButton:active,
.flatButton:focus,
.flatButton:focus:not(:active) {
  color: inherit;
  background-color: var(--button-flat-highlight);
}

.primaryButton {
  color: var(--button-primary);
  background-color: var(--button-primary-background);
}

.primaryButton:active,
.primaryButton:focus,
.primaryButton:focus:not(:active) {
  color: var(--button-primary);
  background-color: var(--button-primary-highlight);
}

.accentButton {
  color: var(--button-accent);
  background-color: var(--button-accent-background);
}

.accentButton:active,
.accentButton:focus,
.accentButton:focus:not(:active) {
  color: var(--button-accent);
  background-color: var(--button-accent-highlight);
}

@media (hover: hover) {
  .button:hover {
    color: var(--button-default);
    background-color: var(--button-default-highlight);
  }
  .flatButton:hover {
    color: inherit;
    background-color: var(--button-flat-highlight);
  }
  .primaryButton:hover {
    color: var(--button-primary);
    background-color: var(--button-primary-highlight);
  }
  .accentButton:hover {
    color: var(--button-accent);
    background-color: var(--button-accent-highlight);
  }
}

@media (hover: none) {
  .button:hover:global(.mdl-button:hover) {
    background-color: var(--button-default-background);
  }
  .flatButton:hover:global(.mdl-button:hover) {
    background-color: var(--button-flat-background);
  }
  .primaryButton:hover:global(.mdl-button:hover) {
    background-color: var(--button-primary-background);
  }
  .accentButton:hover:global(.mdl-button:hover) {
    background-color: var(--button-accent-background);
  }
}
