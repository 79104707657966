.container {
  margin: 0 auto;
  width: auto;
  max-width: 500px;
  max-height: 90%;
  overflow: auto;
  z-index: 100;
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  color: var(--card-text-color);
  background-color: var(--card-background-color);
}

.container::backdrop {
  background: rgba(50, 50, 50, 0.85);
  backdrop-filter: blur(2px);
}

@media screen and (max-width: 480px) {
  .container {
    max-height: 100%;
  }
}

.content {
  color: var(--card-text-color);
}
