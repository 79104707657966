.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 175px;
  /* background-color: limegreen; */
  margin: 0 10px;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
}

.button {
  margin-right: 10px;
}
